body {
    font-family: 'Nunito', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: normal !important;
    margin-bottom: 10px;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

p.loginOr {
    position: relative;
    margin: 10px 0px;
}

p.loginOr:after {
    background: #c9bbbb none repeat scroll 0 0;
    bottom: 3.8px;
    content: "";
    height: 1px;
    left: -180px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 120px;
}

p.loginOr:before {

    background: #c9bbbb none repeat scroll 0 0;
    bottom: 3.8px;
    content: "";
    height: 1px;
    left: 40px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 120px;

}

.underline {
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    text-underline-position: under;
    margin-right: 10px;
    font-size: 32px;
    margin-top: 8px !important;
    margin-bottom: 10px !important;
}

.top {
    background-color: white !important;
    color: #333333 !important;
}

.menuicon {
    justify-content: flex-start;
}


.formText {
    font-family: 'Nunito' !important;
    font-size: 14px !important;
    line-height: 1.5;
    // font-weight: bold !important;
}

.creditText {
    font-family: 'Nunito' !important;
    font-size: 15px !important;
    line-height: 1.5;
    //font-weight: bold;
    font-weight: bold !important;
}

.TextField-without-border-radius fieldset {
    border-radius: 2;
}

.formTextflag {
    padding-bottom: 2px !important;
    font-family: 'Nunito' !important;
    color: #0A2638;
    font-weight: bold !important;
    font-size: 20px !important;
}
.heading {
    font-size: 45px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Nunito' !important;
    font-weight: bold;
    z-index: 1;
}
.banner__items .heading {
    font-size: 45px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Nunito' !important;
    font-weight: bold;
    z-index: 1;
}

.banner__items {
    height: 300px !important;
    background: url(../assets/images/person.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #0A2638;
    position: relative;
}

.banner__items:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000036;
}

.searchbtn-holder {
    position: absolute;
    bottom: -50px;
    right: 0;
}

.searchbtn-holder button.MuiButtonBase-root.MuiButton-root.MuiButton-filledPrimary.btn-search {
    background-color: red !important;


    padding: 10px 20px;
    width: auto;

    height: auto !important;
}

.searchbtn-holder button.MuiButtonBase-root.MuiButton-root.MuiButton-filledPrimary.btn-search p.buttonText {
    font-size: 16px !important;
    text-transform: none;
    color: #fff !important;
}


.menu {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Tiempos' !important;
    font-weight: 'bold';
}

.stepLabel {
    font-family: 'Nunito' !important;
    font-size: 20px !important;
    color: #0A2638 !important;
}

header.header-navbar {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 10px;
}

header.header-navbar .navbar-menu {

    padding: 0px 20px;
}

header.header-navbar .navbar-menu span {
    margin-right: 10px;
}

header.header-navbar .navbar-menu span button {
    padding: 10px;
    line-height: normal;
    font-weight: bold;
    text-transform: none;
}

header.header-navbar .navbar-menu span p {
    font-family: 'Nunito';
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

header.header-navbar .navbar-menu span p.active {
    font-family: 'Nunito';
    /* background-color: #EDBA37; */
    color: black;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    border-bottom: 2px solid #EDBA37;
}

header.header-navbar .navbar-menu span p.inactive {
    font-family: 'Nunito';

    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

header.header-navbar .user-navbarmenu {
    display: flex;
    align-items: center;

}

.user-navbarmenu .loginmenu {

    font-family: 'Nunito';
    text-align: center;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #EDBA37;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold !important;
}

.user-navbarmenu .registermenu {

    font-family: 'Nunito';
    text-align: center;
    display: flex;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #EDBA37;
    border-radius: 5px;
}


.user-navbarmenu .registermenu button.regiclass {
    padding: 0px;
    background-color: transparent;
    color: #000;
    text-transform: none;
    font-family: 'Nunito' !important;
    text-align: center;
    font-weight: bold !important;
    cursor: pointer;
    line-height: normal;
}

.MenuText {
    background-color: #333333 !important;
    color: #FFFFFF !important;
}

.settingsactive {
    font-family: 'Nunito' !important;
    background-color: #EDBA37 !important;
    color: white;
    text-align: center !important;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}

.settingsinactive {
    font-family: 'Nunito' !important;
    background-color: white !important;
    color: #333333;
    text-align: center;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}

.Mui-active {
    color: #333333 !important;
    text-decoration: underline #EDBA37 2px;
    text-underline-position: under;
    font-size: 15px !important;
    font-family: 'Nunito' !important;
}

.Mui-completed {
    font-size: 17px !important;
    font-family: 'Nunito' !important;
}

.MuiStepLabel-label {
    font-weight: bold !important;
    color: #333333 !important;
    font-size: 12px !important;
    //font-size: 17px !important;
    font-family: 'Nunito' !important;
    padding-top: 15%;

}

.vehiclename {
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    color: #333333;
    font-size: 25px !important;
}

.MuiStep-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tripsdiv {
    border-top-left-radius: 20;
    border-top-right-radius: 20;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebar {
    height: 200px;
    width: 350px;
    background-color: #3c3c3c;
    overflow-y: auto;

    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.sidebar::-webkit-scrollbar {
    display: none;
}

.inputRounded .MuiOutlinedInput-root {
    border-radius: 6px;
}

.MuiSelect-select {
    padding-right: 0px !important;
}

.react-tel-input .form-control {
    font-size: 12px !important;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px 14px 7px 45px !important;
    width: 99% !important;
    height: 32px !important;
}

.react-tel-input .selected-flag .flag {
    top: 50% !important;
}

.react-datepicker {
    position: absolute !important;
    right: -290px !important;
    width: max-content;
}

.dateClassfeild {
    padding: 5 !important;

}

.texttimePicker {
    padding-top: 2 !important;
}

.texttimePicker input {

    padding: 0px;
    font-size: inherit;
}

.workflow_holder {
    position: relative;
}

.workflow_box {
    position: absolute;
    top: -105px;
}

.MuiGrid-root.MuiGrid-container.container_topspace {
    margin-top: 30px;
}

.workflow_box .workflow_box_inner {
    background: white;
    padding: 5px 0px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 10px;
}

.workflow_box .workflow_box_inner .workflow_icon {
    height: 50px;
    width: 50px;
}

.MuiStepper-root.MuiStepper-horizontal {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: center;

}

.MuiStep-root.MuiStep-horizontal {
    display: block;
    position: relative;
}

.MuiStepConnector-root.MuiStepConnector-alternativeLabel {}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel {
    top: calc(50% - 10px);
    left: calc(-40%);
    width: 60px;
}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel.Mui-active .MuiStepConnector-line {
    width: 100%;
    min-width: 100%;
    background-size: 130%;
    background-position: center;
    min-height: 32px;
    margin-top: -10px;
    border: none;
}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel.Mui-completed .MuiStepConnector-line {
    width: 100%;
    min-width: 100%;
    background-size: 130%;
    background-position: center;
    min-height: 32px;
    margin-top: -10px;
    border: none;
}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel .MuiStepConnector-line {

    width: 100%;
    margin: auto;
    margin-top: 8px;
    border-top: 1px solid #000;
}

.MuiStep-root.MuiStep-horizontal .MuiStepButton-root {
    padding: 10px 20px;
    margin: 0px;
    width: auto;
    display: block;
    height: auto;
}

.MuiStepLabel-root.MuiStepLabel-horizontal {
    display: flex;
}

.MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer {}

.workflow_box .workflow_box_inner .MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer>div {
    height: 25px;
    width: 25px;
    background-color: #EDBA37;
    border-radius: 6px;
    padding: 5px;
}

.workflow_box .workflow_box_inner .MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer>div .workflow_icon {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-labelContainer .MuiStepLabel-label {
    margin: 0px;
    padding: 0;
}

#footer {
    padding-top: 15px;
    color: white;
    /* font-size: 13px; */
    background-color: #252129;
}

#footer p {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: normal;
}

#footer p.link {
    cursor: pointer;
}
#footer a.app_store_icon{
display: inline-block;
width: 48%;
margin-right: 5px;
}
.app_store_icon img{
    width: 100%;
}
.column_title {
    margin-top: 0px;
    margin-bottom: 10px;
}

#footer a {
    margin-bottom: 5px;
    font-size: 13px;
    display: block;
    text-decoration: none;
    color: #fff;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#footer .copy-right {
    background: black;
    padding: 10px;
    margin-top: 10px;
}

#footer .copy-right p {
    color: white;
    text-align: center;
    font-size: 12px;
    margin: 0;
}

.MuiModal-root .MuiBox-root {
    border: none;
    background-color: transparent;
}

.MuiModal-root .MuiButtonBase-root.MuiIconButton-root {
    background-color: #fff;
    border-radius: 100px;
}

.shadow_box {
    background: white;
    padding: 20px;
    padding-top: 90px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 20px;
    margin: auto;

    position: relative;
}

.shadow_box .box_title {
    position: absolute;
    left: 0;
    top: 20px;
    border-radius: 0px 20px 20px 0px;
    padding: 10px 20px;
    background-color: #EDBA37;

}

.shadow_box .box_title p,
.shadow_box .box_title p.MuiTypography-root {
    color: #000;
    font-family: 'Nunito';
    font-size: 20px !important;
}

.shadow_box .box_title p.MuiTypography-root .usermenu button.MuiButtonBase-root {
    font-family: 'Nunito';
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

.usermenu button.MuiButtonBase-root p.MuiTypography-root {
    font-family: 'Nunito';
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

.usermenu p.MuiTypography-root {
    font-family: 'Nunito';
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

button.primary-btn,
button.MuiButtonBase-root.MuiButton-filledPrimary {
    background-color: #EDBA37 !important;
    color: #000 !important;
    border-radius: 6px !important;
    padding: 10px 20px !important;
    height: auto !important;
}

button.primary-btn:hover,
button.MuiButtonBase-root.MuiButton-filledPrimary:hover {
    background-color: #b77a2a !important;
    color: #ffffff;
    border-radius: 6px !important;
    padding: 10px 20px !important;
    height: auto !important;
}

button.primary-btn .MuiTypography-root,
button.MuiButtonBase-root.MuiButton-filledPrimary .MuiTypography-root {
    color: #000;
}

button.primary-btn:hover .MuiTypography-root,
button.MuiButtonBase-root.MuiButton-filledPrimary:hover .MuiTypography-root {
    color: #ffffff;
}

.MuiFormGroup-root .MuiTypography-root {
    font-size: 14px !important;
    top: 15px !important;
    left: 15px !important;
}

.MuiFormGroup-root .MuiFormControl-root.MuiTextField-root,
.MuiFormGroup-root .react-datepicker__input-container .MuiFormControl-root.MuiTextField-root {
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #edba37 !important;
    border-radius: 6px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
}

.MuiFormGroup-root .MuiFormControl-root {
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #edba37 !important;
    border-radius: 6px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
}

.MuiFormGroup-root .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    width: 100%;
    border: none !important;
}

.MuiFormGroup-root .react-tel-input {
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #edba37 !important;
    border-radius: 6px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
}

.MuiFormGroup-root .react-tel-input .flag-dropdown {
    border: none;
    border-right: 1px solid #edba37;
}

.MuiFormGroup-root .react-tel-input .form-control {
    border: none;
}

button.MuiButtonBase-root.MuiTab-root {
    color: #ffffff;
    border-radius: 6px;
    margin: 5px 5px 5px 0px;
    background-color: #333333;
    padding: 10px 20px;
    height: 30px !important;
    text-transform: capitalize;
    min-height: auto !important;
    line-height: normal;
    max-width: 100% !important;
}

button.MuiButtonBase-root.MuiTab-root.tabheight {
    color: #ffffff;
    border-radius: 6px;
    margin: 5px 5px 5px 0px;
    background-color: #333333;
    padding: 10px 20px;
    height: 30px !important;
    text-transform: capitalize;
    min-height: auto !important;
    line-height: normal;
    max-width: 100% !important;
}

button.MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #000000;
    background-color: #EDBA37;
}

.MuiTabs-scroller .MuiTabs-indicator {
    display: none !important;
}

.MuiToggleButtonGroup-root.toggleRadio {
    margin-bottom: 10px;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root {
    background-color: transparent;
    position: relative;
    padding-left: 30px;
    font-size: 14px !important;
    height: auto;
    line-height: normal;
    padding-top: 7px;
    padding-bottom: 7px;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    border: 2px solid #333333;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root.Mui-selected {
    background-color: transparent;
    color: #EDBA37;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root.Mui-selected:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 8px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #EDBA37;
    border: none;
    z-index: 1;
}



.vehicleBox {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.vehicleBox_img {
    width: 100%;
    height: 200px;
}

.vehicleBox_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.vehicleBox_details {
    width: 100%;
    padding: 10px 10px;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
}

.sum_listitem {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 1%;
    margin-bottom: 10px;
}

.img_icon {
    background-color: #EDBA37;
    height: 30px;
    width: 30px;
    padding: 4px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.img_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.listitem_value {
    display: block;
}

.descriptionList {

    padding: 10px 0px;
    display: flex;
    flex-direction: column;

}

.descriptionList .descriptionItem {
    display: flex;
    align-items: center;
}

.descriptionList .descriptionItem .descriptionItemImg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    padding: 8px;
    background-color: #edba37;
    border-radius: 6px;

}

.descriptionList .descriptionItem p.descriptionText {
    padding: 10px !important;
    color: #000 !important;
    font-size: 14px !important;
}

.description_holder {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.vehicle_price_holder {}

.vehicle_price_holder p.pricetext {
    font-size: 25px !important;
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold;
    font-weight: bold !important;
    text-align: center;
}

.tripsummary_holder {}

.sum_list {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 15px;
    padding: 10px;
}

.dropdown_formcontrol {
    background-color: white !important;
    border: 1px solid #edba37 !important;
    border-radius: 4px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
}

.texttimePicker {}

button.newbooking {
    color: white;
    background-color: rgb(51, 51, 51);
    border-radius: 6px;
    height: 30px;
    padding: 5px 10px;
}

.username_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;
    border-radius: 6px;
    background-color: #EDBA37;
}

.username_menu .MuiAvatar-root {
    margin-right: 10px;
    background-color: #b77a2a;
    height: 30px;
    width: 30px;
}

.booking_box {
    border-radius: 6px !important;
    background-color: #ffffff;
    border: 1px solid #edba37;
}

.wallet_box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.wallet_amt {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    width: 40%;
    background-color: #edba37;
    align-items: center;
}

.topup_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding: 10px;
    justify-content: center;
    flex-wrap: wrap;

}

.MuiList-root.MuiList-padding.MuiMenu-list>div {
    width: 100%;
}

.MuiList-root.MuiList-padding.MuiMenu-list>div .MuiSvgIcon-root {
    border-radius: 50px !important;
}

.location_point {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 0;
    padding: 10px;
}

.location_point p.loctext {
    text-align: center;
    margin-bottom: 10px;
    background-color: #EDBA37;
    border-radius: 6px;
    padding: 5px 0px;
    font-weight: bold;
}

.MuiCardContent-root.card_content {
    border-radius: 6px;
    padding: 0px;
}

.container_topspace {
    margin-top: 30px !important;
}



.triptype_title {
    background-color: #EDBA37;
    color: #000;
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    font-family: 'Nunito';
    padding: 5px;
    position: relative;
}

.location_startpoint {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 40%;
}

.location_stoppoint {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 40%;
}

.location_startpoint .location_innerbox,
.location_stoppoint .location_innerbox {
    width: 100%;
}

.location_midpoint {
    border: none;
    margin-left: 10px;
    min-height: 2px;
    width: 20%;
    height: 1px;
    background-color: #EDBA37;
}

.mainstop {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    align-items: baseline;
}

.location_spot {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    width: 100%;
    justify-content: space-around;

}

.location_innerbox {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 40%;
}

.location_innerbox .img_icon {
    margin: 0;
}

.triptype_name {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.location_innerbox .loctext {

    font-size: 12px;
    text-align: center;
}

.total_fare {
    text-align: right;
    padding: 10px;
    background-color: #b77a2a;
    color: #fff;
}

.total_fare p {
    font-size: 22px;
}

.contactimg_wrapper {}

.contactimg_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.companyname_head {
    font-size: 36px;
    margin: 0px;
    margin-bottom: 10px;
}

.contactinfo_wrapper {
    padding: 40px 20px;
}

.section_heading {
    font-size: 36px;
    margin: 0;
    margin-bottom: 10px;
}

.leftside_textholder {
    padding: 40px;
}

.rightside_textholder {
    padding: 40px;
}

.leftside_imgholder {}

.leftside_imgholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.rightside_imgholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.rightside_imgholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.about_sec4 {
    padding: 40px 0px;
}

.about_sec4 .section_heading {
    text-align: center;
}

.box_content {
    text-align: center;
    padding: 20px;
}

.icon_img {
    width: 80px;
    margin: 10px auto;
}

.icon_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

ul.service_point li {
    font-size: 16px;

}

.box_heading {
    font-size: 26px;
    margin: 10px 0px;
}

.MuiListItemText-root button.MuiButtonBase-root.MuiButton-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-weight: normal !important;
    text-transform: none;
    font-size: 16px;
}

.full_contentholder {
    padding: 20px 40px;
}

.row_title {
    font-size: 24px;
}

.row_para {
    margin-bottom: 20px;
    text-align: justify;
}

.row_list {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.row_list li {}

.readmore_link {
    cursor: pointer;
    color: #EDBA37;
}

.our_service {
    padding: 40px
}

.our_service .section_heading {
    text-align: center;
    margin-bottom: 20px;
}

ul.service_list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

ul.service_list>li {
    width: 46%;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
}

ul.service_list>li .topic_heading {
    margin: 0px;
    margin-bottom: 15px;
    line-height: normal;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

ul.service_list>li ul.topic_point_list {}

.MuiPaper-root.MuiAccordion-root.Mui-expanded{
    box-shadow: 0px 0px 20px 5px #ddd;
    border-radius: 10px;
}
.faq_accordion_holder {
    margin: 30px 0px;
    padding: 0px 20px;
}
.MuiPaper-root.MuiAccordion-root{
border: none;
box-shadow: none;
margin-bottom: 20px;
}


.MuiAccordionDetails-root{
    padding: 15px 20px;
}
.MuiButtonBase-root.MuiAccordionSummary-root.topic_question {
    border: 1px solid #ddd;

    border-radius: 10px;
    font-size: 22px;
    position: relative;
    padding-left: 55px;
    min-height: 56px;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.topic_question {
    background-color: #EDBA37;

}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.topic_question .MuiAccordionSummary-content.Mui-expanded {

    margin: 0px;
}

.MuiButtonBase-root.MuiAccordionSummary-root.topic_question:hover {

    background-color: #EDBA37;

}
.MuiAccordionSummary-expandIconWrapper{
    position: absolute;
    left: 15px;
}
.more_info_section {
    margin: 30px 0px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
}

.more_info_section h3 {
    margin: 0px 0px 15px 0px;
    font-size: 28px;
}
.more_info_section p{
    margin-bottom: 20px;
}
@media screen and (max-width:480px) {
    .username_menu {
        padding: 5px 5px
    }

    .username_menu .MuiAvatar-root {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }

    .username_menu .headerstyle {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 50px;
        overflow: hidden;
    }

    .banner__items {
        height: 200px !important;
    }

    .leftside_textholder {
        padding: 20px;
    }

    .rightside_textholder {
        padding: 20px;
    }

    .section_heading {
        font-size: 22px;
    }

    p {
        font-size: 12px;
    }

    ul.service_point li {
        font-size: 12px;

    }

    .MuiModal-root .MuiBox-root {
        padding: 0px;
        width: 80%;
    }
}